import React, { useEffect } from "react"
import { navigate } from "gatsby"

export default props => {
  const pathDestination = props.pageContext.url
  useEffect(() => {
    navigate(pathDestination)
  }, [])

  return <div></div>
}
